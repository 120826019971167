<template>
    <div>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>College Placement Dashboard</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row class='pl-4'>
                <v-col md="2">
                    <label><b>Academic Year &nbsp;<small style="color:red;">*</small></b></label>
                    <v-autocomplete autocomplete="off" :items="ay_list" item-text="ay" item-value="id"
                        v-model="ay"></v-autocomplete>
                </v-col>
                <v-col md="2">
                    <label><b>Organization &nbsp;<small style="color:red;">*</small></b></label>
                    <v-autocomplete :items="institute_list" item-text="name" item-value="id" v-model="institute"
                        @change="getProgramType()"></v-autocomplete>
                </v-col>
                <v-col md="2">
                    <label><b>Program Type &nbsp;<small style="color:red;">*</small></b></label>
                    <v-autocomplete autocomplete="off" :items="programtype_list" item-text="name" item-value="id"
                        v-model="programtype" @change="getProgramAndYear()"></v-autocomplete>
                </v-col>
                <v-col md="2">
                    <label><b>Program &nbsp;<small style="color:red;">*</small></b></label>
                    <v-autocomplete autocomplete="off" :items="program_list" item-text="name" item-value="id"
                        v-model="program"></v-autocomplete>
                </v-col>
                <v-col md="2">
                    <label><b>Year &nbsp;<small style="color:red;">*</small></b></label>
                    <v-autocomplete autocomplete="off" :items="year_list" item-text="name" item-value="id"
                        v-model="year"></v-autocomplete>
                </v-col>
                <v-col md="12">
                    <label><b>Placement Type &nbsp;<small style="color:red;">*</small></b></label> :
                    <span v-for="item in placementtype_list" :key="item.name">
                        <input style="width:18px;height:18px;margin-left:10px;" type="checkbox" :id="item.name"
                            v-model="item.checked" :checked="item.checked"> {{ item.name }}
                    </span>
                </v-col>
                <v-col md="2">
                    <v-btn class="primary ma-5 mt-10" @click="getcollegeplacementdashboardreport">Get Details</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="regdashboard_list.length != 0" @click="exportexcel()" color="success" dark class="mb-2">
                    <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>

            <v-simple-table id="exceltable" class="elevation-1" :search="search" v-if="regdashboard_list.length != 0">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th @click="sortTable('srno')" class="text-left" > Sr. No. </th>
                            <th @click="sortTable('company_name')" class="text-left"> Company</th>
                            <th @click="sortTable('companyoffering_code')" class="text-left"> Company Offering Code</th>
                            <th @click="sortTable('min_package')" class="text-left"> Min Package</th>
                            <th @click="sortTable('max_package')" class="text-left"> Max Package</th>
                            <th @click="sortTable('count')" class="text-left"> Total</th>
                            <th class="text-left" v-for="item in programlist" :key="item.id">{{ item.abbrivation }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in regdashboard_list" :key="item.srno">
                            <td>{{ item.srno }}</td>
                            <td>{{ item.company_name }}</td>
                            <td>{{ item.companyoffering_code }}</td>
                            <td>{{ item.min_package }}</td>
                            <td>{{ item.max_package }}</td>
                            <td>
                                <v-toolbar-title style="color:blue;font-size:16px;text-decoration: underline;"
                                    v-if="item.count != 0"
                                    @click="route(item.companyofferingid, progid, programtypeid, orgid, yearid, placementtypeid, ayid)">
                                    {{ item.count }}
                                </v-toolbar-title>
                                <span v-else>{{ item.count }}</span>
                            </td>
                            <td v-for="cmp in item.companyofferingprogramlist" :key="cmp.progid">
                                <v-toolbar-title style="color:blue;font-size:16px;text-decoration: underline;"
                                    v-if="cmp.count != 0 && cmp.count != 'NA'"
                                    @click="route(item.companyofferingid, cmp.progid, cmp.programtypeid, orgid, yearid, placementtypeid, ayid)">
                                    {{ cmp.count }}
                                </v-toolbar-title>
                                <span v-else>{{ cmp.count }}</span>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2"><span style="float:right;font-weight:600;">Total</span></td>
                            <td>
                                <v-toolbar-title style="color:blue;font-size:16px;text-decoration: underline;"
                                    v-if="totalstudentcount != 0"
                                    @click="route('All', progid, programtypeid, orgid, yearid, placementtypeid, ayid)">
                                    {{ totalstudentcount }}
                                </v-toolbar-title>
                                <span v-else>{{ totalstudentcount }}</span>
                            </td>
                            <td v-for="prt in programtotal_list" :key="prt.progid">
                                <v-toolbar-title style="color:blue;font-size:16px;text-decoration: underline;"
                                    v-if="prt.count != 0 && prt.count != 'NA'"
                                    @click="route('All', prt.progid, prt.programtypeid, orgid, yearid, placementtypeid, ayid)">
                                    {{ prt.count }}
                                </v-toolbar-title>
                                <span v-else>{{ prt.count }}</span>
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </v-simple-table>
        </div>

        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import Language from "../AdminMaster/Language.vue";
import { table2excel } from "../jquery.table2excel";
export default {
    components: { Language },
    data() {
        return {
            snackbar_msg: "",
            snackbar: false,
            color: "",
            search: "",
            overlay: false,
            ascending: false,
        sortColumn: '',

            ay_list: [],
            institute_list: [],
            program_list: [],
            year_list: [],
            placementtype_list: [],
            programtype_list: [],

            ay: null,
            institute: null,
            program: null,
            programtype: null,
            year: null,
            placementtype: [],

            ayid: null,
            progid: null,
            programtypeid: null,
            ptid: null,
            yearid: null,
            orgid: null,

            regdashboard_list: [],
            programlist: [],
            programtotal_list: [],
            totalstudentcount: null,
        };
    },
    mounted() {
        this.init();
    },
    computed: {
    "columns": function columns() {
      if (this.regdashboard_list.length == 0) {
        return [];
      }
      return Object.keys(this.regdashboard_list[0])
    }
  },
    methods: {
        sortTable(col) {
            console.log(col);
            if (this.sortColumn === col) {
                console.log(col);
                this.ascending = !this.ascending;
            } else {
                this.ascending = true;
                this.sortColumn = col;
            }

            var ascending = this.ascending;

            this.regdashboard_list.sort(function(a, b) {
                if (a[col] > b[col]) {
                return ascending ? 1 : -1
                } else if (a[col] < b[col]) {
                return ascending ? -1 : 1
                }
                return 0;
            })
        },
    
        exportexcel() {
            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: "PLACEMENT_DASHBOARD_REPORT", //do not include extension
                fileext: ".xls" // file extension
            });
        },

        init() {
            axios.post("/PlacementReport/getcollegeplacementfilters").then((res) => {
                if (res.data.msg == "200") {
                    this.ay_list = res.data.ay_list;
                    this.ay = res.data.ay;
                    this.institute_list = res.data.institute_list;
                    this.placementtype_list = res.data.placementtype_list;

                    if (this.institute_list.length > 0) {
                        this.institute = this.institute_list[0].id;
                    }
                    this.getProgramType();
                } else {
                    //console.log("error fetching data!");
                }
            });
        },

        getcollegeplacementdashboardreport() {
            this.regdashboard_list = []
            this.programlist = []
            this.programtotal_list = []
            this.placementtype = []
            for (var i = 0; i < this.placementtype_list.length; i++) {
                if (this.placementtype_list[i].checked)
                    this.placementtype.push(this.placementtype_list[i].id)
            }

            const params = {
                instituteid: this.institute,
                programtypeid: this.programtype,
                programid: this.program,
                yearid: this.year,
                ay: this.ay,
                placementtype: this.placementtype,
            };
            this.overlay = true;

            axios
                .post("/PlacementReport/getcollegeplacementdashboardreport", params)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.msg == "200") {
                        this.regdashboard_list = res.data.regdashboard_list;

                        this.programtotal_list = res.data.programtotal_list;
                        this.totalstudentcount = res.data.totalstudentcount;
                        this.programlist = res.data.programlist;

                        this.ayid = res.data.ayid;
                        this.placementtypeid = res.data.placementtypeid;
                        this.progid = res.data.progid;
                        this.yearid = res.data.yearid;
                        this.programtypeid = res.data.programtypeid;
                        this.orgid = res.data.orgid;
                        this.programlist = res.data.programlist;

                        if (this.regdashboard_list.length < 1) {
                            this.showSnackbar("red", "Data Not Found");
                        }
                    } else if (res.data.msg == "402") {
                        this.showSnackbar("red", res.data.error);
                    }
                });
        },

        getProgramType() {
            const data = {
                instituteid: this.institute,
            };
            axios
                .post("PlacementReport/getProgramTypeByOrganization", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.programtype_list = res.data.programtype_list;
                        if (this.programtype_list.length > 1) {
                            this.programtype = "All";
                            this.programtype_list.push({ name: 'All', text: '' });
                        } else if (this.programtype_list.length > 0) {
                            this.programtype = this.programtype_list[0].id;
                        }
                        this.getProgramAndYear();
                    } else {
                        this.showSnackbar("green", res.data.msg1);
                    }
                }).catch((error) => {
                    window.console.log(error);
                }).finally(() => { });
        },

        getProgramAndYear() {
            const data = {
                instituteid: this.institute,
                programtypeid: this.programtype,
            };
            axios
                .post("PlacementReport/getProgramAndYearByOrganization", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.program_list = res.data.program_list;
                        if (this.program_list.length > 1) {
                            this.program = "All";
                            this.program_list.push({ name: 'All', text: '' });
                        } else if (this.program_list.length > 0) {
                            this.program = this.program_list[0].id;
                        }

                        this.year_list = res.data.year_list;
                        if (this.year_list.length > 1) {
                            this.year = "All";
                            this.year_list.push({ name: 'All', text: '' });
                        } else if (this.year_list.length > 0) {
                            this.year = this.year_list[0].id;
                        }
                    } else {
                        this.showSnackbar("green", res.data.msg1);
                    }
                }).catch((error) => {
                    window.console.log(error);
                }).finally(() => { });
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        route(companyofferingid, progid, programtypeid, orgid, yearid, placementtypeid, ayid) {
            let routeData = this.$router.resolve({ name: 'LearnerCollegePlacementDashboard', query: { companyofferingid: companyofferingid, progid: progid, programtypeid: programtypeid, orgid: orgid, yearid: yearid, placementtypeid: placementtypeid, ayid: ayid } });
            window.open(routeData.href, '_blank');
        },
    },
};
</script>
<style scoped>.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}
table th:hover {
        background: gainsboro;
        cursor: pointer;
      }
.tc-title {
    background-image: linear-gradient(-90deg, #fff, #057996);
    color: #fff;
    border-radius: 3px;
}</style>    